import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MedicalOrderService } from 'src/app/core/services/medical-order.service';
import { CloseRouteDialogMessage, ComponentFinishLoadingMessage, MessageBusService, NavigationBackMessage } from 'src/app/core/services/message-bus.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { ComponentName } from 'src/app/shared/enums/component-name.enum';
import { CONCEPTOS_ID, MEDICAL_REQUEST_TYPE, MY_MEDICAL_ORDER_PRINT_SYSTEM_AREA, TIPO_CONCEPTOS_ID, TITLE_MAPPING } from 'src/app/shared/enums/medical-request-type.enum';
import { AttentionsHistory } from 'src/app/shared/interfaces/attentions-history';
import { ClinicalHistoryItem } from 'src/app/shared/interfaces/clinical-history-item';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { PublicProfile } from 'src/app/shared/models/people/public-profile.model';
import { PatientVerificationComponentData, PatientVerificationDataModel } from 'src/app/shared/models/workflow/models/patient-verification-data.model';
import { RouteDialogType } from '../../dialogs/route-dialog/route-dialog.component';
import { MedicalOrder } from 'src/app/shared/interfaces/medical-order';
import { Location } from 'src/app/shared/models/people/location.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { BaseService } from 'src/app/core/services/base.service';
import { PatientPartial } from 'src/app/shared/interfaces/patient-partial';
import { ClinicContact } from 'src/app/shared/interfaces/clinic-contact';
import { RequestsToVerifyConfirmationResponse, RequestsToVerifyService } from 'src/app/core/services/requests-to-verify.service';
import { FormatoHojaReporte, MedicalOrderFile, MedicalOrderReport, PrintsService, ReporteCategoriaConceptoDTO } from 'src/app/core/services/prints.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-summary-medical-order',
  templateUrl: './summary-medical-order.component.html',
  styleUrls: ['./summary-medical-order.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup(ComponentName.SUMMARY_MEDICAL_ORDER)
export class SummaryMedicalOrderComponent implements IControlComponent, OnInit {

  @Input() inWorflow: boolean = true;
  atenciones: ClinicalHistoryItem[] = [
    { nombreConcepto: '', items: [ { nombreConcepto: '' }, { nombreConcepto: '' }, { nombreConcepto: '' }, { nombreConcepto: '' }, { nombreConcepto: '' } ] },
    { nombreConcepto: '', items: [ { nombreConcepto: '' }, { nombreConcepto: '' }, { nombreConcepto: '' } ] }
  ];
  componentData: PatientVerificationComponentData;
  comentarios?: ClinicalHistoryItem;
  diagnosticos = '';
  diagnosticosLabel = 'diagnosis';
  data: any;
  initialLoading = true;
  loading = false;
  model = new  PatientVerificationDataModel();
  isAndroid = false;
  history: AttentionsHistory = {
    solicitud: { fechaInicio: '1990-04-02', idResponsableServicio: 0, idPersonaEmisora: 0, idTipoSolicitud: 29 },
    historialAtencion: { atenciones: []},
    ubicacionFotoLogoTitulo: '',
    empresaPortal: { denominacion: 'N/A', numeroTelParticular:'N/A', numeroTelCelular: 'N/A', direccionLaboral: 'N/A' }
  };
  dateString = 'N/A';
  language: string;
  localidad: Location;
  patient: PatientPartial;
  publicProfile?: PublicProfile | null;
  title = 'N/A';
  requestType = 0;
  requestTypeName = 'N/A';
  requestNumber = 'N/A';
  buttonSecondaryText = 'shared.medicalOrder.summary.buttonText2';
  buttonPrimaryText = 'shared.medicalOrder.summary.buttonTextPrescriptions';
  private ngUnsubscribe = new Subject();
  idPaciente: number;

  showHtmlComment: boolean = false;
  htmlComment: SafeHtml = '';

  file: File;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private baseService: BaseService,
    private messageBusService: MessageBusService,
    private medicalOrderSevice: MedicalOrderService,
    private requestsToVerifyService: RequestsToVerifyService,
    private publicProfileService: PublicProfileService,
    private medicalOrderService: MedicalOrderService,
    private printsService: PrintsService,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private breakpointObserver: BreakpointObserver,
    private sanitizer: DomSanitizer
  ) {
    this.language = locale.dateLanguage ?? 'es-AR';
    this.messageBusService.onNavigateBack().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onNavigateBackNext.bind(this));
    this.breakpointObserver.observe(Breakpoints.Handset).pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.mobileNext.bind(this));
  }

  ngOnInit(): void {
    const idVerificacion: string = this.route.snapshot.params.idVerificacion;
    if (this.inWorflow) {
      this.requestsToVerifyService.confirmation(idVerificacion).subscribe((confirmModel:RequestsToVerifyConfirmationResponse) => {

        this.idPaciente = confirmModel.idPaciente;

        this.medicalOrderSevice.get(idVerificacion).subscribe({ next: this.processAttentionsHistory.bind(this) });
      });
    } else {
      this.medicalOrderSevice.getById(idVerificacion)
        .pipe(map(this.mapMedicalOrderIntoHistory.bind(this)))
        .subscribe(this.processAttentionsHistory.bind(this));
    }
  }

  showNumbers(): boolean {
    return MEDICAL_REQUEST_TYPE.LABORATORY_ORDER == this.history?.solicitud?.idTipoSolicitud;
  }

  private onNavigateBackNext(x: NavigationBackMessage) {
    if (x.dialogId != RouteDialogType.MY_ORDER_SUMMARY) return;
    this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
  }

  private mobileNext(x: BreakpointState) {
    if (!x.matches) return;
    this.buttonSecondaryText = 'shared.medicalOrder.summary.buttonTextMobile2';
  }

  private mapMedicalOrderIntoHistory(order: MedicalOrder): AttentionsHistory {
    const result: AttentionsHistory = {
      empresaPortal: order.empresaPortal,
      historialAtencion: {
        atenciones: order.detalles.map(this.mapMedicalOrderDetails),
        contactoClinico: {idPaciente: order.idPacientePhr} as ClinicContact,
      },
      solicitud: {
        fechaInicio: order.fecha,
        idEmpresa: order.idEmpresaPortal,
        idPersonaEmisora: order.idPacientePhr,
        idResponsableServicio: order.idResponsableDoctor,
        idTipoSolicitud: order.idTipoSolicitud,
        numeroSolicitud: order.numeroSolicitud
      },
      ubicacionFotoLogoTitulo: order.ubicacionFotoLogoTitulo
    };
    return result;
  }

  private mapMedicalOrderDetails(item: ClinicalHistoryItem): ClinicalHistoryItem {
    const args = JSON.parse(item.jsonOrdenMedicaDetalle || '{}');
    let cantidad = args?.cantidad ?? 0;

    return { ...item, cantidad };
  }

  private processAttentionsHistory(args: AttentionsHistory): void {
    this.history = args;

    this.setTitle(this.history?.solicitud.idTipoSolicitud, this.history?.solicitud.numeroSolicitud);

    this.setDate(this.history?.solicitud.fechaInicio);

    this.parseControlData();

    this.requestType = this.history?.solicitud.idTipoSolicitud ?? 0;
    this.atenciones = this.filterAtenciones(args.historialAtencion.atenciones);
    this.atenciones.forEach(x => this.setItems(x, args));
    this.comentarios = args.historialAtencion.atenciones.find(x => x.idConcepto == CONCEPTOS_ID.COMENTARIO);

    if(this.comentarios?.valorConcepto){
      this.showHtmlComment = this.baseService.isSerializedHtml(this.comentarios?.valorConcepto);
      this.htmlComment = this.sanitizer.bypassSecurityTrustHtml(this.comentarios?.valorConcepto);
    }
    
    this.diagnosticos = this.getDiagnosis(args.historialAtencion.atenciones);

    const contactoClinico$ = args.historialAtencion.contactoClinico
      ? this.patientService.getPartial(args.historialAtencion.contactoClinico.idPaciente)
      : of(null);

    const publicProfile$ = this.publicProfileService.getPublicProfileByIds([this.history.solicitud.idResponsableServicio]);

    const file$ = this.getFile();

    forkJoin([contactoClinico$, publicProfile$, file$]).subscribe({
      next: ([patient, publicProfile, file]) => {
      if (patient) {
        this.patient = patient;
      }
      this.getPublicProfileByIdNext(publicProfile);

      this.file = file;

      this.sendComponentFinishLoadingMessage();
      this.initialLoading = false;
      }
    });
  }

  private getFile(): Observable<File> {

    const body: MedicalOrderReport = {
      valorLocalizacion: 'es',
      idAreaSistema: this.history.solicitud.idAreaSistema ?? -1,
      idAreaSistemaImpresion: MY_MEDICAL_ORDER_PRINT_SYSTEM_AREA[this.history.solicitud.idTipoSolicitud ?? -1] ,
      idEmpresa: this.history.solicitud?.idEmpresa ?? -1,
      idPersona: this.history.solicitud?.idPersonaEmisora ?? -1,
      idResponsableImpresion: this.history.solicitud?.idResponsableServicio ?? -1,
      urlLogoEmpresa: this.history.ubicacionFotoLogoTitulo,
      htmlReporte: '',
      formatoHoja: FormatoHojaReporte.CARTA,
      idPaciente: this.history.historialAtencion.contactoClinico?.idPaciente ?? -1,
      idResponsableServicio: this.history.solicitud?.idResponsableServicio ?? -1,
      idContactoClinico: this.history.historialAtencion.contactoClinico?.id ?? -1,
      urlFirma: this.publicProfile?.firmaImg ?? '',
      idLocalidad: -1,
      fechaOrdenMedica: new Date(),
      comentario: this.comentarios?.valorConcepto ?? '',
      diagnosticos: this.diagnosticos,
      numeroOrden: this.medicalOrderService.buildRequestNumber(this.history.solicitud.idTipoSolicitud, this.history.solicitud.numeroSolicitud),
      categorias: this.obtenerCategoriasPorTiposolicitud(this.history.solicitud.idTipoSolicitud ?? -1, this.atenciones)
    };

    return this.printsService.getPdf(body).pipe(map((response)=>{
      return this.buildFile(response);
    }));
  }

  private buildFile(response: MedicalOrderFile): File {
    const byteCharacters = atob(response.pdfBase64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const file = new File([blob], response.fileName + '.pdf', { type: 'application/pdf' });

    return file;  
  }

  private obtenerCategoriasPorTiposolicitud(idTipoSolicitud: number, conceptos: ClinicalHistoryItem[]): ReporteCategoriaConceptoDTO[] {
    if (idTipoSolicitud == MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION) {
      return [{nombre: "Otros", conceptos: this.obtenerCategoriasPorTiposolicitud(-1, conceptos)}]
    }
    return conceptos.map(this.mapearConceptos.bind(this));
  }

  private mapearConceptos(concepto: ClinicalHistoryItem): ReporteCategoriaConceptoDTO {
    return {
      conceptos: concepto.items?.map(this.mapearConceptos.bind(this)),
      comentario: concepto.valorConcepto,
      nombre: concepto.nombreConcepto
    }
  }
  
  private setTitle(idTipoSolicitud = 0, numeroSolicitud = 0): void {
    const currentRequestTypeName = TITLE_MAPPING[idTipoSolicitud] || '';

    this.requestNumber = this.medicalOrderSevice.buildRequestNumber(idTipoSolicitud, numeroSolicitud);
    this.requestTypeName = currentRequestTypeName;
    this.title = `${currentRequestTypeName}Short`;
  }

  private setDate(args?: string): void {
    if (!args) return;
    this.dateString = this.baseService.getBasicDateFormat(new Date(args), true);
  }

  private parseControlData(): void {
    this.loading = true;

    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as PatientVerificationDataModel;

      if(this.model.idPaciente > 0){
        this.idPaciente = this.model.idPaciente;
      }

      this.componentData = this.model.configData;
    }

    this.loading = false;
  }

  private filterAtenciones(atenciones: ClinicalHistoryItem[]): ClinicalHistoryItem[] {
    return atenciones.filter(x => !x.idConceptoObjeto && x.idConcepto != CONCEPTOS_ID.COMENTARIO && x.idTipoConcepto != TIPO_CONCEPTOS_ID.DIAGNOSTICO);
  }

  private getDiagnosis(atenciones: ClinicalHistoryItem[]): string {
    const diagnosis = atenciones
      .filter(x => x.idTipoConcepto == TIPO_CONCEPTOS_ID.DIAGNOSTICO)
      .map(x => x.valorCodigoTerminoReferenciaConceptoClinico || this.truncate(x.nombreConcepto));
    let result = diagnosis.join(', ');

    if (diagnosis.length > 1) {
      const lastItem = diagnosis.pop();
      result = `${diagnosis.join(', ')} y ${lastItem}`;
      this.diagnosticosLabel = 'diagnoses';
    }

    return result;
  }

  private truncate(text: string, maxLength = 28): string {
    let result = text;
    if (text.length > maxLength) {
      result = `${text.substring(0, maxLength)}...`
    }
    return result;
  }

  private setItems(x: ClinicalHistoryItem, args: AttentionsHistory): void {
    x.items = args.historialAtencion.atenciones.filter(y => y.idConceptoObjeto == x.idConcepto);
  }

  private sendComponentFinishLoadingMessage(): void {
    if (!this.data) return;

    const event = new ComponentFinishLoadingMessage();
    
    event.idFormulario = this.data.idFormulario;
    event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }

  private getPublicProfileByIdNext(args: (PublicProfile | null)[]): void {
    this.publicProfile = args[0];
  }
}