<div class="error-dialog-wrapper" ngClass.lt-md="mobile" [hidden]="licenseLoading">
    <div mat-dialog-content>
        <p class="ups">Upsss</p>        
        <p class="dialog-message">
            {{ 'shared.errorDialog1.message' | translate:locale.language }}
        </p>
        <span class="error-text" innerHtml="{{ data.message }}"></span>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-raised-button
            tabindex="-1"
            color="primary"
            (click)="close()"
        >
            {{ 'shared.errorDialog1.closeButton' | translate:locale.language }}
        </button>
    </div>
</div>
