<div class="unlicensed-login-signup-menu-container">
    <!-- Language -->
    <app-anonymous-language-menu class="language-menu"></app-anonymous-language-menu>
  
    <button mat-button class="menu-button" [matMenuTriggerFor]="unlicensedmenu" yPosition="above" *ngIf="showAnonymousMenu | async">
      <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-menu icon phr-iconset"></mat-icon>              
    </button>

    <mat-menu #unlicensedmenu="matMenu" class="unlicensed-mat-menu-container">          
      
      <div class="company-logo-container">
        <app-company-logo></app-company-logo>
      </div>        
     
      <span class="menu-title">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-ISO icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text1' | translate:locale.language}}</span>            
      </span>
     
      <!-- Appointments -->
      <button mat-menu-item (click)="onViewAppointmentsClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-date-2 icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text2' | translate:locale.language}}</span>            
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window icon phr-iconset"></mat-icon>
      </button>
      <!-- Cliniweb for patients -->
      <button mat-menu-item (click)="onCliniwebForPatientsClick()">            
        <mat-icon fontSet="phr-iconset" class="phr-iconset-help icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text3' | translate:locale.language}}</span>   
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window icon phr-iconset"></mat-icon>         
      </button>

      <!-- Logout -->
      <button mat-menu-item (click)="onCliniwebLogoutClick()" *ngIf="showLogout">            
        <mat-icon fontSet="phr-iconset" class="phr-iconset-logout icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text8' | translate:locale.language}}</span>         
      </button> 
      
      <mat-divider></mat-divider>                    
      <span class="menu-title cliniweb-title">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-ISO icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text4' | translate:locale.language}}</span>                  
      </span>

      <!-- Login -->
      <button mat-menu-item (click)="goToPortal()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-login icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text5' | translate:locale.language}}</span> 
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window icon phr-iconset"></mat-icon>           
      </button>
      <!-- Create public profile -->
      <button mat-menu-item (click)="goToPortalPublicProfilePage()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-star icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text6' | translate:locale.language}}</span>  
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window icon phr-iconset"></mat-icon>          
      </button> 
      <!-- Cliniweb for doctors -->
      <button mat-menu-item (click)="onCliniwebForDoctorsClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-help icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text7' | translate:locale.language}}</span> 
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window icon phr-iconset"></mat-icon>           
      </button> 
      
    </mat-menu>
</div>