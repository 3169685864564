import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { FloatingComponentsMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { ContainerType } from 'src/app/shared/enums/container-type.enum';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-unlicensed-menu',
    templateUrl: './unlicensed-menu.component.html',
    styleUrls: ['./unlicensed-menu.component.css'],
    encapsulation: ViewEncapsulation.None
  })
  export class UnlicensedMenuComponent implements OnDestroy, OnInit {
    showAnonymousMenu: Observable<boolean>;
    private ngUnsubscribe = new Subject();

    showLogout: boolean = false;
  
    constructor(      
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private messageBusService: MessageBusService,
      private translation: L10nTranslationService,
      private dialogRef: MatDialog,
      private authService: AuthService,
      private router: Router,
      private appStorageService: AppStorageService,
    ) {
        const onShowSignupLoginHeaderMenu = this.messageBusService.onShowSignupLoginHeaderMenu();
        this.showAnonymousMenu = this.messageBusService.onHideAnonymousMenu()
        .pipe(takeUntil(this.ngUnsubscribe), map(() => false), startWith(true), withLatestFrom(onShowSignupLoginHeaderMenu), map(x => x[0] && x[1]), delay(100));

        this.showLogout = this.authService.isLoggedIn();
    }

    ngOnInit(): void {
        this.messageBusService.onLoginCompleted()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(msg => {
            this.showLogout = true;
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onViewAppointmentsClick(): void {
        var cliniwebPhrLink = environment.cliniwebPhrUrl;
        
        window.open(cliniwebPhrLink + "/mis-citas", '_blank');        
    }

    onCliniwebForPatientsClick(): void {
        let cliniwebUrl = environment.cliniwebUrl + "##comoFuncionaPacientes";
        
        window.open(cliniwebUrl, '_blank');
    }

    goToPortal(): void {
        let portalUrl = environment.portalUrl;        

        window.open(portalUrl, '_blank');
    }
    
    goToPortalPublicProfilePage(): void {
        let portalUrl = environment.portalUrl + "perfiles";    
        
        window.open(portalUrl, '_blank');
    }

    onCliniwebForDoctorsClick(): void {
        let cliniwebUrl = environment.cliniwebUrl + "Doctor##comoFuncionaDoctores";

        window.open(cliniwebUrl, '_blank');
    }

    onCliniwebLogoutClick(): void{
        let dialogData = new DialogData();
    
        dialogData.title = this.translation.translate("shared.profileMenu.text13");    
        dialogData.confirmText = this.translation.translate("shared.profileMenu.text14");   
        dialogData.showHeaderIcon = false;
        //dialogData.headerIconClass = "phr-iconset-info";   
        dialogData.showCancelButton = true;
        dialogData.cancelText = this.translation.translate("shared.profileMenu.text15");
    
        const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
            data: dialogData,
            panelClass: ['cliniweb-theme-dialog', 'confirm-logout-dialog', 'confirm-logout-animation-in']
        });
    
        dialogRef.afterClosed().subscribe(accepted => {    
            if (accepted) {
              this.appStorageService.removeSessionItem(STORAGE.SIGNUP_WORKFLOW_VERIFICATION_TYPE);
              this.removeFloatingComponentToHeader();
              this.authService.logout();

              this.router.navigate(['/welcome/login'], {queryParams: { homeUrl: '/'}});
            }
        });
    }

    removeFloatingComponentToHeader(){
        let message = new FloatingComponentsMessage();
        let dummyComponentData = new FormComponentData();
        dummyComponentData.contenedor = ContainerType.HEADER;
    
        message.componentsData = [dummyComponentData];
    
        this.messageBusService.sendFloatingComponents(message);
    }
}