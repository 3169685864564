
<div class="provider-card-container">
    <mat-card>
        <mat-card-content>
            <div class="header-card-container" (click)="goToProfile($event)">

                <app-provider-card-profile
                    [isDoctorProfile]="isDoctorProfile()"
                    [profile]="provider"
                ></app-provider-card-profile>
                
            </div>

            <div class="locations-container" [ngClass]="{'without-indicator': locations.length == 1 }">
                <mat-tab-group mat-align-tabs="start" [disablePagination]="false" (selectedTabChange)="setLocationSelected($event)">
                    <mat-tab *ngFor="let location of locations; index as i; trackBy: locationTrackBy">
                        <ng-template mat-tab-label>
                            <span class="tab-title">
                                {{getLocationTabTitle(location)}}
                            </span>
                        </ng-template>
                        <div class="tab-content">

                            <app-provider-card-location
                                [location]="location"
                                [isDoctorProfile]="isDoctorProfile()"
                                [provider]="provider"
                                (goToProfile)="goToProfile($event)"
                            ></app-provider-card-location>

                            <app-provider-card-contacts
                                [location]="location"
                                [isDoctorProfile]="isDoctorProfile()"
                            ></app-provider-card-contacts>
                            <!-- <div class="social-wrapper" *ngIf="!(isDoctorProfile() || isClinicProfile())">
                                <div class="social-text">
                                    <mat-icon fontSet="phr-iconset" class="phr-iconset-url icon"></mat-icon>
                                    <a *ngIf="provider.sitioWeb" [href]="getWebsiteUrl()" target="_blank">Web</a>
                                    <span *ngIf="provider.sitioWeb" class="social-separator">•</span>
                                    <button class="social-networks-btn" mat-button [matMenuTriggerFor]="socialNetworks" (menuOpened)="loadSocialNetworks()">
                                        <span>
                                            {{ 'shared.searchResults.providerCard.socialNetworks' | translate:locale.language }}
                                        </span>                                        
                                        <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down"></mat-icon>
                                    </button>
                                    <mat-menu #socialNetworks="matMenu" class="social-networks-panel" >
                                        <div *ngIf="socialNetworksLoaded && !socialNetworksLoading">
                                            <a mat-menu-item 
                                                *ngFor="let socialNetworkElement of socialNetworksList"
                                                [href]="socialNetworkElement.url"
                                                target="_blank">
                                                <mat-icon fontSet="phr-iconset" [ngClass]="socialNetworkElement.iconClassName"></mat-icon>
                                                {{socialNetworkElement.text}}
                                            </a>
                                        </div>
                                        <div class="social-networks-loading-wrapper" *ngIf="socialNetworksLoading">
                                            <mat-spinner [diameter]="18"></mat-spinner>
                                        </div>
                                        <div class="social-networks-empty-wrapper" *ngIf="socialNetworksLoaded && !socialNetworksLoading && socialNetworksList.length == 0">
                                            {{provider.nombre}}
                                            <br/>
                                            {{ 'shared.searchResults.providerCard.socialNetworksEmptyState' | translate:locale.language }}
                                        </div>
                                    </mat-menu>
                                </div>
                            </div> -->
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>
