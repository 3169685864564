import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnlinePaymentRequest } from 'src/app/shared/models/process/online-payment-request.model';
import { HttpBaseService } from './http-base-service';
import { HttpResponseModel } from 'src/app/shared/models/http-response-model';
import { UserPaymentItem } from 'src/app/shared/models/finance/payments/user-payment-item.model';
import { UserPayment } from 'src/app/shared/models/finance/payments/user-payment.model';
import { HttpHeaders } from '@angular/common/http';
import { TempDocument } from 'src/app/shared/models/process/document.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpBaseService) { }      

  getOnlinePaymentRequest(idVerificacion: string): Observable<OnlinePaymentRequest>{
    return this.http.get<OnlinePaymentRequest>("api/pagos/por-verificar/" + idVerificacion, null, false);
  }

  createOnlinePaymentRequest(onlinePaymentRequest: OnlinePaymentRequest): Observable<string>{
    return this.http.post<string>("api/pagos/por-verificar", onlinePaymentRequest, null, false);
  }

  updateOnlinePaymentRequest(idVerificacion: string, onlinePaymentRequest: OnlinePaymentRequest): Observable<number>{
    let url = `api/pagos/por-verificar/${idVerificacion}`;
    return this.http.put<number>(url, onlinePaymentRequest);
  }

  confirmOnlinePaymentRequest(idVerificacion: string, onlinePaymentRequest: OnlinePaymentRequest): Observable<HttpResponseModel<OnlinePaymentRequest>>{
    let url = `api/pagos/por-verificar/${idVerificacion}/confirmacion`;
    return this.http.post<HttpResponseModel<OnlinePaymentRequest>>(url, onlinePaymentRequest);
  }

  getUserPayments(): Observable<UserPaymentItem[]>{
    return this.http.get<UserPaymentItem[]>("api/pagos/", null, true);
  }

  getUserPayment(transaccionId: number): Observable<UserPayment>{
    return this.http.get<UserPayment>("api/pagos/" + transaccionId, null, true);
  }  

  getUserPaymentPdf(transaccionId: number): Observable<any> {
    let url = "api/pagos/" + transaccionId + "/pdf?language=es";       

    let headers = new HttpHeaders();    
    headers = headers.set('Accept', 'application/pdf');

    return this.http.post<any>(url, null, { headers: headers, responseType: 'blob' as 'json' });
  }
}

