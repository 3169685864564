import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { ActivatedRoute, Params } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { AvatarUploadComponentOptions, AvatarUploadedFileModel } from '../avatar-upload/avatar-upload.component';
import { DependentRequest } from '../../models/people/dependent.model';
import { GrowthMeasurementType } from '../../models/clinic-history/growth-measurement-type.model';
import { GrowthMeasurementsService } from 'src/app/core/services/growth-measurements.service';
import { GrowthMeasurements } from '../../models/clinic-history/growth-measurements.model';
import { Subject } from 'rxjs';
import { IControlComponent } from '../../interfaces/control-component';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-growth-charts',
  templateUrl: './growth-charts.component.html',
  styleUrls: ['./growth-charts.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('GrowthChartsComponent')
export class GrowthChartsComponent implements IControlComponent, OnInit, OnDestroy {
  data: any;
  private ngUnsubscribe = new Subject();
  private tabChangeSubject = new Subject<number>();
  loading: boolean;
  tabLoading: boolean = false;
  model: GrowthChartsModel = new GrowthChartsModel();
  avatarUploadOptions : AvatarUploadComponentOptions;

  showDoctorSelect: boolean = true;
  selectedDoctorId: number = 0;

  selectedTabIndex = 0;
  initializedTabs: boolean[];

  patientId?: number;

  isDropdownOpen = false;
  selectedDoctor: any = null;

  @ViewChild('customSelect') customSelect: ElementRef;

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (this.customSelect && !this.customSelect.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  toggleDropdown(event: Event) {
    event.stopPropagation(); 
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectDoctor(doctor: any) {
    this.selectedDoctor = doctor;
    this.selectedDoctorId = doctor.id; 
    this.isDropdownOpen = false;

    this.getGrowthMeasurementsPatient(this.selectedDoctorId);
  }

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private baseService: BaseService,
    private growthMeasurementsService: GrowthMeasurementsService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.initAvatarUploadOptions();

    this.growthMeasurementsService.getMeasurementTypes()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(measurementTypes=>{
      this.initGrowthChartTabs(measurementTypes);
    });

    this.tabChangeSubject
    .pipe(takeUntil(this.ngUnsubscribe), debounceTime(570))
    .subscribe(this._onTabChange.bind(this));

    this.route.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((queryParams) => {
      this.configByParams(queryParams);
    },
    error => {
      console.log(error)

      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updateDoctor($event: any){
    this.selectedDoctorId = $event.value;
    this.getGrowthMeasurementsPatient(this.selectedDoctorId);
  }

  onAvatarUploaded(file: AvatarUploadedFileModel) {
    this.model.patientAvatar = file?.webUrl;
    this.model.patientObject.ubicacionLogo = file?.webUrl;

    if(this.model.patientDependentRelationId > 0){
      //dependiente
      var model = new DependentRequest();

      model.idPersona = this.model.patientId;    
      model.idRelacionDependiente = this.model.patientDependentRelationId;
      model.nombre = this.model.patientFirstName;
      model.apellido = this.model.patientLastName;
      model.fechaNacimiento = this.model.patientBirthDate;
      model.sexo = this.model.patientGender;
      model.tipoRelacion = this.model.patientRelationType;
      model.ubicacionLogo = this.model.patientAvatar;

      this.userService.createOrUpdateDependent(model);
    }
    else{
      this.userService.updateUserLoggedIn(this.model.patientObject);
    }    
  }

  onTabChange(event: any): void {
    this.tabChangeSubject.next(event)
  }

  private _onTabChange(index: number): void {
    const trueIndex = this.initializedTabs.findIndex(x => x);

    if (trueIndex >= 0 && index != trueIndex) {
      this.initializedTabs[trueIndex] = false;
      this.setSelectedTabIndex(index);
    }
  }

  private configByParams(params: Params){
      if(params["idResponsableServicio"]){
        this.showDoctorSelect = false;
        this.selectedDoctorId = params["idResponsableServicio"];
      }

      if(params["p"]){
        this.patientId = +params["p"];

        //dependiente
        this.userService.getUserDependents()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(dependents => {
          
          let dependent = dependents.find(d=> d.idPersona == +params["p"]);

          if(dependent){
            this.model.patientId = dependent.idPersona;
            this.model.patientFirstName = dependent.nombre;
            this.model.patientLastName = dependent.apellido;
            this.model.patientAvatar = dependent.ubicacionLogo;
            this.model.patientBirthDate = dependent.fechaNacimiento;
            this.model.patientGender = dependent.sexo;
            this.model.patientRelationType = dependent.tipoRelacion;
            this.model.patientDependentRelationId = dependent.id;
            this.model.patientObject = dependent;

            this.loadGrowthData();
            this.loading = false;
          }
        });

      }
      else{
        this.userService.getUserPersonLoggedIn()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(p => {
          if (p) {
            this.model.patientId = p.id;
            this.model.patientFirstName = p.nombre;
            this.model.patientLastName = p.apellido;
            this.model.patientAvatar = p.ubicacionLogo;
            this.model.patientBirthDate = p.fechaNacimiento;
            this.model.patientGender = p.sexo;
            this.model.patientObject = p;

            this.loadGrowthData();

            this.loading = false;
          }        
        });
      }
  }

  private loadGrowthData(){
    const doctorId = this.model.growthMeasurements && this.model.growthMeasurements.idResponsableMedicion > 0 
      ? this.model.growthMeasurements.idResponsableMedicion
      : this.selectedDoctorId > 0
        ? this.selectedDoctorId
        : -1;

    this.getGrowthMeasurementsPatient(doctorId);
  }

  private getGrowthMeasurementsPatient(doctorId: number) {
    this.tabLoading = true;

    this.growthMeasurementsService.getByPatientId(
      this.model.patientId,
      this.baseService.getCompanyId(),
      doctorId
    ).subscribe(growthMeasurements => {
      this.model.growthMeasurements = growthMeasurements;

      const doctors = growthMeasurements.mediciones.map(medicion => {
        return {
          id: medicion.idResponsableAtencion,
          nombre: medicion.nombreResponsableAtencion
        };
      });

      const uniqueDoctors = this.getUniqueDoctors(doctors);

      if (this.showDoctorSelect && uniqueDoctors.length > 0) {
        if (uniqueDoctors.length === 1) {
          this.selectedDoctor = uniqueDoctors[0];
          this.selectedDoctorId = uniqueDoctors[0].id;
        } else {
          this.selectedDoctor = null;
          this.selectedDoctorId = 0;
        }
      }

      this.tabLoading = false;
    });
  }

  private getUniqueDoctors(doctors: any[]) {
    const uniqueDoctorMap = new Map();
    doctors.forEach(doctor => {
      uniqueDoctorMap.set(doctor.id, doctor);
    });
    return Array.from(uniqueDoctorMap.values());
  }

  private initAvatarUploadOptions(){
    this.avatarUploadOptions = new AvatarUploadComponentOptions();
    this.avatarUploadOptions.requiredFileType = "image/png,image/jpeg";
    this.avatarUploadOptions.selectFileLabel = "añadir foto";
    this.avatarUploadOptions.changeFileLabel = "Editar";
    this.avatarUploadOptions.showFileOnceUpload = true;

    this.avatarUploadOptions.selectFileLabel = this.translation.translate('shared.growthCharts.selectFile');
    this.avatarUploadOptions.changeFileLabel = this.translation.translate('shared.growthCharts.changeFile');
    this.avatarUploadOptions.changePictureLabel = this.translation.translate('shared.growthCharts.changePicture');
    this.avatarUploadOptions.deleteFileLabel = this.translation.translate('shared.growthCharts.deleteFile');
  }

  private initGrowthChartTabs(measurementTypes: GrowthMeasurementType[]){
    this.model.growthTabsModel = measurementTypes;

    this.initializedTabs = new Array(this.model.growthTabsModel.length).fill(false);
    this.setSelectedTabIndex(0);
  }

  private setSelectedTabIndex(index: number): void {
    this.selectedTabIndex = index;
    this.initializedTabs[this.selectedTabIndex] = true;
  }

}

export class GrowthChartsModel{
  patientId: number;
  patientAvatar: string;
  patientFirstName: string;
  patientLastName: string;
  patientBirthDate: Date;
  patientGender: string;
  patientRelationType: number;
  patientDependentRelationId: number;
  patientObject: any;

  growthTabsModel: GrowthMeasurementType[];
  growthMeasurements: GrowthMeasurements;
}



